import React from 'react'
import styles from './footer-note.module.css'

type Props = {
  title: string
  description: string
}

export const FooterNote = ({title, description}: Props) => (
  <div className={styles.footerNote}>
    <p className={styles.title}>{title}</p>
    <p className={styles.description}>{description}</p>
  </div>
)
