import React, {ReactNode} from 'react'
import styles from './single-step.module.css'

type Props = {
  title: string
  picture: string
  children: ReactNode
}

export const SingleStep = ({title, picture, children}: Props) => (
  <div className={styles.wrapper}>
    <h4 className={styles.title}>{title}</h4>
    <div className={styles.card}>
      <div>
        <img src={picture} className={styles.picture} alt="" />
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.details}>{children}</div>
      </div>
    </div>
  </div>
)
