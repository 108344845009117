import React from 'react'
import {FooterNote, SingleStep} from '..'
import styles from './next-steps.module.css'

export const NextSteps = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <h3 className={styles.heading}>
        You are almost ready to start playing.
        <br />
        Just a few more steps...
      </h3>
      <SingleStep
        title="Step1: Check your email"
        picture="/images/card-placeholder.svg"
      >
        <p>
          We&apos;ve just sent you an email to confirm your purchase, and we
          also set up a new account over at
        </p>
        <p>
          <a
            href="https://secretofthesourcecode.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            secretofthesourcecode.com
          </a>
        </p>
        <p>This is where you can:</p>
        <ul>
          <li>play the game</li>
          <li>view the progress of your students</li>
          <li>make account changes</li>
        </ul>
        <FooterNote
          title="Can't find this email?"
          description="Give it a sec luv. Sit down and have a spot of tea."
        />
      </SingleStep>
      <SingleStep
        title="Step2: Reset your password"
        picture="/images/card-placeholder.svg"
      >
        <p>
          Before getting started, you&apos;ll need to reset the automatically
          generated password we selected for you.
        </p>
        <p>Click on the link in the email above and pick a password.</p>
        <p>Make sure to pick one that you&apos;ll remember!</p>
        <FooterNote
          title="Already set a password?"
          description="If you already have an account, just login as normal."
        />
      </SingleStep>
      <SingleStep
        title="Step3: Log in and play"
        picture="/images/card-placeholder.svg"
      >
        <p>
          Enter your email (the one used on this purchase) and your new password
          to log in to the game.
        </p>
        <p>You&apos;ll then be asked if you are the parent or student.</p>
        <p>
          Click on parent to edit student info, view progress, update your
          account, look for troubleshooting help.
        </p>
        <p>Click on student to begin playing the game.</p>
        <FooterNote
          title="Want to use another email to login?"
          description="Send us a message and we can transfer registration to other email address."
        />
      </SingleStep>
      <h3 className={styles.footer}>That&apos;s it, time to play.</h3>
    </div>
  </section>
)
